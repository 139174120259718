// src/pages/Services.jsx
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../LanguageProvider';
import Header from '../Reutilizables/Header/Header';
import Footer from '../Reutilizables/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Services.css";

function Services() {
  
  useEffect(() => {
    document.body.scrollTop = 0; // Para Safari
    document.documentElement.scrollTop = 0; // Para Chrome, Firefox, IE y Opera
  }, []);

  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  return (
    <div className="d-flex flex-column min-vh-100 bg-celular">
      <Header changeLanguage={changeLanguage} i18n={i18n}/>
      <div className='container flex-grow-1 text-white'>
        {language === 'en' ? (
          <h1 className='font-reross h-color hTitle2'>SERVICES WE OFFER</h1>
        ) : (
          <h1 className='font-reross h-color hTitle2'>SERVICIOS QUE OFRECEMOS</h1>
        )}

        {language === 'en' ? (
          <h4 className=''>We work on-site or remotely!</h4>
        ) : (
          <h4 className=''>¡Trabajamos de forma presencial o virtual!</h4>
        )}
        <div className="row justify-content-around">
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708065/Translation_tll3sy.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTitleTranslation')}</h4>
              <p>{t('servicesTextTranslation')}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708065/simultaneousInterpretation_iqxq7m.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTextTitle1')}</h4>
              <p>{t('servicesText1')}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708072/consecutiveInterpretation_u5ry6g.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTextTitle2')}</h4>
              <p>{t('servicesText2')}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708064/sightTranslation_p687uw.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTextTitle3')}</h4>
              <p>{t('servicesText3')}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708071/chuchotage_eqkfwz.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTextTitle4')}</h4>
              <p>{t('servicesText4')}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className="service-box">
              <img src="https://res.cloudinary.com/dos14xhdl/image/upload/v1729708074/equipment_bdwf7h.jpg" alt="" className='img-fluid'/>
              <h4 className="text-white">{t('servicesTextTitle5')}</h4>
              <p>{t('servicesText5')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Services;
